.service_img {
  /* height: 100px; */
  border-radius: 15px;
}

.sec_sp {
  margin-bottom: calc(3rem + 5.128vw);
}

.table td,
.table th {
  color: var(--text-color);
}

.t_border {
  border-color: var(--text-color) !important;
}

.progress-title {
  font-size: 16px;
  font-weight: 700;
  margin: 15px 0 20px;
  font-family: "Raleway";
}

.progress {
  height: 5px;
  background: var(--secondary);
  border-radius: 0;
  box-shadow: none;
  margin-bottom: 30px;
  overflow: visible;
}

.progress .progress-bar {
  position: relative;
  background: var(--text-color);
  animation: animate-positive 2s;
  overflow: visible;
  opacity: 0.9;
}

.progress .progress-value {
  position: absolute;
  top: -30px;
  right: 8px;
  font-size: 17px;
  font-weight: bold;
  font-style: italic;
  color: var(--text-color);
}

.service_take {
  position: relative;
  margin-top: 10px;
  padding-top: 10px;
}
.service_take::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 0.5px;
  border-top: dashed 0.5px #ffffff52;
  background-clip: content-box; /* creates space between the dashes */
}

@-webkit-keyframes animate-positive {
  0% {
    width: 0%;
  }
}

@keyframes animate-positive {
  0% {
    width: 0%;
  }
}

.section-title {
  font-size: 45px;
}

.service__title {
  font-size: 1.5rem;
  padding: 8px 0;
  border-bottom: solid 2px var(--secondary-color);
  margin-bottom: 10px;
}

.service-section .service-category-title {
  padding-bottom: 4px;
}

/*! CSS Used keyframes */

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}
